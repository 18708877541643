import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React, {useState} from 'react';
import ProductHero from './modules/views/ProductHero';
import RegisterDialog from './modules/views/ProductHero';
import ApplyForm from "./modules/form/ApplyForm";

function Index() {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <React.Fragment>
      {/*<RegisterDialog*/}
      {/*    // onClose={_onClose}*/}
      {/*    setDialogOpen={setDialogOpen}/>*/}
      <ProductHero setDialogOpen={setDialogOpen}/>
    </React.Fragment>
  );
}

export default withRoot(Index);
