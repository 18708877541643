import React, {useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
// import RegisterDialog from "../form/RegisterDialog";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import * as firebase from "firebase";
require("firebase/functions");


const styles = (theme) => ({
  background: {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: `../html/stars.html`,
    // backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes, setDialogOpen } = props;

  const [open, setOpen] = React.useState(false);
  const [buttonText, setButtonText] = React.useState('Apply for Access');
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [textDisabled, setTextDisabled] = React.useState(true);

  const email = useRef();
  const purpose = useRef();
  const newName = useRef();

  const register = useCallback(() => {
      setDialogOpen(true);
      console.log('we out here')
  }, [setDialogOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRegister = useCallback( () => {
    setOpen(false);
    const shareEmail = firebase.functions().httpsCallable('shareEmail');
    shareEmail({
      email: email.current.value,
      purpose: purpose.current.value,
      name: newName.current.value
    })
    setButtonText('Application Received');
    setButtonDisabled(true);
    setTextDisabled(false);
  }, [email, purpose, newName, setButtonDisabled, setButtonText, setTextDisabled]);

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Lost in Spacs?
      </Typography>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            inputRef={newName}
          />
          <TextField
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            inputRef={email}
          />
          <TextField
            id="outlined-multiline-static"
            label="Intended Use Case"
            multiline
            rows={4}
            fullWidth
            inputRef={purpose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRegister} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        Our proprietary data and API let you navigate the universe of Special Purpose Acquisition Companies with confidence
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        onClick={handleClickOpen}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button>
      <Typography color="inherit" align="center" variant="h6" marked="center" hidden={textDisabled}>
        Application Received
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
