import React from 'react';
import './App.css';
import Index from './app/Home'
import * as firebase from "firebase";

const firebaseConfig = {
apiKey: "AIzaSyA_VaceeNOYZX9OAip1cUpwJmhsuq2Km88",
authDomain: "spac-eee93.firebaseapp.com",
databaseURL: "https://spac-eee93.firebaseio.com",
projectId: "spac-eee93",
storageBucket: "spac-eee93.appspot.com",
messagingSenderId: "726198809587",
appId: "1:726198809587:web:268e2ccd64a1ad9b4d1886",
measurementId: "G-66V2JX1N9Y"
};

const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};

function App() {
  initFirebase();
  return (
    <div className="App">
      <Index/>
    </div>
  );
}

export default App;
